import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Lazy load components
const Home = lazy(() => import("./Pages/Home"));
const About = lazy(() => import("./Pages/About"));
const Work = lazy(() => import("./Pages/Work"));
const Contact = lazy(() => import("./Pages/Contact"));
const Project1Details = lazy(() => import("./Pages/Project1Details"));
const Project2Details = lazy(() => import("./Pages/Project2Details"));
const Project3Details = lazy(() => import("./Pages/Project3Details"));
const DesignWorks = lazy(() => import("./Pages/DesignWorks"));
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy"));

// Loading component
const Loading = () => <div>Loading...</div>;

function App() {
  return (
    <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/work" element={<Work />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/project-1acre.in" element={<Project1Details />} />
          <Route path="/project-smgroup" element={<Project2Details />} />
          <Route path="/project-salonbooking" element={<Project3Details />} />
          <Route path="/design-works" element={<DesignWorks />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
